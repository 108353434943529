<template>
  <div>
    <div class="home_landing">
      <v-img height="100vh" src="../../assets/E-commerce.png"></v-img>
      <div class="navigation_btns">
        <v-btn
          color="white"
          class="m-r-10 font-weight-bold"
          style="text-transform: initial"
        >
          Αρχική / Marketplace
        </v-btn>
        <v-btn
          dark
          color="#F9B54A"
          class="m-r-10 font-weight-bold"
          style="text-transform: initial"
        >
          Business Courier
        </v-btn>
        <v-btn
          dark
          color="#F9B54A"
          class="m-r-10 font-weight-bold"
          style="text-transform: initial"
        >
          Blog
        </v-btn>
      </div>
      <div class="address">
        <div
          style="display: flex; align-items: center; padding: 5px; height: 70px"
        >
          <font-awesome-icon
            :icon="faMapMarkerAlt"
            color="grey"
            class="m-r-10 font-size-24"
          ></font-awesome-icon>
          <VuetifyGoogleAutoComplete
            id="originAddress"
            v-model="address.text"
            dense
            classname="form-control"
            color="orange"
            label="Γράψε την διεύθυνσή σου..."
            :hide-details="false"
            country="gr"
            :validate-on-blur="true"
            @placechanged="getAddressData($event)"
          >
          </VuetifyGoogleAutoComplete>
          <font-awesome-icon
            :icon="faChevronDown"
            color="black"
            class="m-10"
          ></font-awesome-icon>
          <v-btn
            dark
            large
            color="orange"
            class="m-r-10 font-weight-bold"
            style="text-transform: initial"
          >
            Παράγγειλε τώρα
          </v-btn>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import VuetifyGoogleAutoComplete from "vuetify-google-autocomplete";
import {
  faMapMarkerAlt,
  faChevronDown,
} from "@fortawesome/pro-solid-svg-icons";
export default {
  components: {
    VuetifyGoogleAutoComplete,
  },
  data() {
    return {
      faMapMarkerAlt,
      faChevronDown,
      address: {
        text: null,
        lat: null,
        lon: null,
      },
    };
  },
  methods: {
    getAddressData() {},
  },
  mounted() {
    const labels = document.querySelectorAll(".v-label");
    labels.forEach((label) => {
      label.classList.add("v-label--active");
    });
  },
};
</script>

<style lang="scss">
.home_landing {
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
}
.navigation_btns {
  position: absolute;
  left: 0%;
  top: 0;
  transform: translate(10%, 100%);
  color: white;
  font-weight: bold;
}
.address {
  position: absolute;
  left: 0%;
  top: 30%;
  font-weight: bold;
  background-color: rgb(209, 209, 209);
  border-radius: 6px;
}
</style>
